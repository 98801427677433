import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignSave = props => (
  <Layout>
    <Helmet>
      <title>Service Design - Save</title>
      <meta name="Service Design - Save" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.savecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">SERVICE DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">UX STRATEGY</span>
            <h1>save.</h1>
            <h3>
              An Internet of Things enabled payment & tracking service that
              centralises all your payment methods and improves your payment
              experience making it personalised & seamless. It also helps you to
              manage and analyse your expenses effectively & efficiently.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  The aim of the project was to design a new generation of
                  payment experiences across digital & physical touchpoints.
                  Designing a service for the cashless society comes with its
                  fair share of challenges. Security issues, data sharing
                  between different stakeholders, privacy of personal data,
                  especially in a crowded environment while making payment, and
                  gaining of people’s trust in using the different elements that
                  makes up the cashless experience.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2016
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 4 days
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing of financial
                  services.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Christina Sadek, Piti
                  Pravichpaibul & Elisabeth Graf.
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Primary & Secondary Research,
                  Analysing research, Ideation & final detailing of concept and
                  Storyboarding.
                </span>
              </div>
            </div>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.savechallenge1.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.savechallenge2.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>

            <p className="full-text">
              Initially it was vital to understand the bigger picture so as to
              curate a research plan and a project timeline laying out goals and
              deliverables at each stage of the project.
            </p>
            <div className="process-image">
              <Img fluid={props.data.save_process.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Desk research was carried out in order to understand the current
              state of the cashless society and how transactions are made. And
              which government policies and technological innovations are
              enabling the growth of the cashless society.
            </p>
            <div className="image">
              <Img fluid={props.data.saveprocess3.childImageSharp.fluid} />
            </div>
            <div className="process">
              <div className="process-left">
                <p>
                  User behaviour observation was carried out to get a deep
                  understanding and empathise with the consumer's actions, their
                  state of mind and their contextual environment when they make
                  payments and manage their expenses.
                </p>
              </div>
              <div className="process-right">
                <p>
                  This research was analysed to build multiple personas (each
                  group was asked to focus on only one persona) and a user
                  journey/experience was mapped out in order to identify the
                  various pain points and opportunity areas.
                </p>
              </div>
            </div>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.saveprocess4.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.saveprocess5.childImageSharp.fluid} />
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.saveprocess6.childImageSharp.fluid} />
            </div>
            <div className="process">
              <div className="process-left">
                <p>
                  The mapping of the user experience allowed us to identify
                  vairous insights and opportunities for developing concepts. We
                  also identified various challenges that we will need to tackle
                  in order to have a UX strategy and service-experience that is
                  feasible and sucessfull.
                </p>
              </div>
              <div className="process-right">
                <p>
                  We found that, currently people have too many cards to manage
                  during payments, and also it can be time-consuming and
                  difficult to categorise the different types of expenses in
                  their daily lives; especially for people who are entrepreneurs
                  and have to juggle their finances between their personal &
                  business life.
                </p>
              </div>
            </div>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.saveprocess7.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.saveprocess8.childImageSharp.fluid} />
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.saveprocess9.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              All the insights from the research analysis was used to ideate
              various concepts. One of the concepts were further consolidated to
              design the final Save service.
            </p>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.saveprocess10.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess11.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess12.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess13.childImageSharp.fluid} />
              </Slider>
            </div>
            <p className="full-text">
              The service experience of Save for 2 main use-cases; making a
              payment and management of finances, was visualised through a
              storyboard.
            </p>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.saveprocess14.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess15.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess16.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess17.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess18.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess19.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess20.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess21.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess22.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess23.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess24.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess25.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess26.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess27.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess28.childImageSharp.fluid} />
                <Img fluid={props.data.saveprocess29.childImageSharp.fluid} />
              </Slider>
            </div>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    The Save service provides a new clutter-free way of making
                    everyday payments thus streamlining the payment experience.
                    It allows a way in which information of multiple credit,
                    debit, private label & loyalty cards can be stored and
                    accessed efficiently. Thus providing a secure & efficient
                    payment experience with one press of a button - no wallet
                    needed - an all-in-one smart device & wallet.
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    Save leverages on the IoT to tracks user location, purchase
                    history/behaviour, emails & calendar activities. It is a
                    smart system that learns user behaviours and provides
                    personalised suggestions related to payment & future
                    spending. It also helps you to save time and effort by
                    visualising financial information in an understandable &
                    manageable way, provide future projections and assist you in
                    setting of financial goals.
                  </p>
                </div>
              </div>
              <h3>
                Overall, it is a living services that wraps around people’s
                lives and evolves with usage by learning, acting & predicting to
                match user's expectations so that they can to live a life that
                is seamless, stress free & brings a smile on their faces.
              </h3>
            </div>
            <div className="image">
              <Img fluid={props.data.saveoutcome30.childImageSharp.fluid} />
            </div>
          </section>
          <section id="footertopcta" className="inner">
            <p className="text arrow-front">
              check out my
              <br />
              <Link to="/#one">featured work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-keycrime-private">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">keycrime.</div>
                <div className="text">
                  A crime analysis software that uses Machine Learning &
                  Artificial Intelligence for predictive policing.
                </div>
                <div className="tags">
                  <ul>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-1">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 1).</div>
                <div className="text">
                  Unique experiences that bring people together over home cooked
                  meals hosted by local chefs in neighbourhoods.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignSave

export const fluidSaveImage = graphql`
  fragment fluidSaveImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    savecover: file(
      relativePath: { eq: "projects/service/save/cover/save-cover-image.jpg" }
    ) {
      ...fluidSaveImage
    }
    savechallenge1: file(
      relativePath: { eq: "projects/service/save/challenge/1.jpg" }
    ) {
      ...fluidSaveImage
    }
    savechallenge2: file(
      relativePath: { eq: "projects/service/save/challenge/2.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess3: file(
      relativePath: { eq: "projects/service/save/process/3.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess4: file(
      relativePath: { eq: "projects/service/save/process/4.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess5: file(
      relativePath: { eq: "projects/service/save/process/5.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess6: file(
      relativePath: { eq: "projects/service/save/process/6.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess7: file(
      relativePath: { eq: "projects/service/save/process/7.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess8: file(
      relativePath: { eq: "projects/service/save/process/8.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess9: file(
      relativePath: { eq: "projects/service/save/process/9.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess10: file(
      relativePath: { eq: "projects/service/save/process/10.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess11: file(
      relativePath: { eq: "projects/service/save/process/11.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess12: file(
      relativePath: { eq: "projects/service/save/process/12.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess13: file(
      relativePath: { eq: "projects/service/save/process/13.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess14: file(
      relativePath: { eq: "projects/service/save/process/14.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess15: file(
      relativePath: { eq: "projects/service/save/process/15.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess16: file(
      relativePath: { eq: "projects/service/save/process/16.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess17: file(
      relativePath: { eq: "projects/service/save/process/17.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess18: file(
      relativePath: { eq: "projects/service/save/process/18.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess19: file(
      relativePath: { eq: "projects/service/save/process/19.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess20: file(
      relativePath: { eq: "projects/service/save/process/20.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess21: file(
      relativePath: { eq: "projects/service/save/process/21.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess22: file(
      relativePath: { eq: "projects/service/save/process/22.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess23: file(
      relativePath: { eq: "projects/service/save/process/23.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess24: file(
      relativePath: { eq: "projects/service/save/process/24.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess25: file(
      relativePath: { eq: "projects/service/save/process/25.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess26: file(
      relativePath: { eq: "projects/service/save/process/26.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess27: file(
      relativePath: { eq: "projects/service/save/process/27.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess28: file(
      relativePath: { eq: "projects/service/save/process/28.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveprocess29: file(
      relativePath: { eq: "projects/service/save/process/29.jpg" }
    ) {
      ...fluidSaveImage
    }
    saveoutcome30: file(
      relativePath: { eq: "projects/service/save/outcome/30.jpg" }
    ) {
      ...fluidSaveImage
    }
    save_process: file(
      relativePath: { eq: "projects/process/save-process.png" }
    ) {
      ...fluidSaveImage
    }
    project1: file(relativePath: { eq: "projects/13.png" }) {
      ...fluidSaveImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidSaveImage
    }
  }
`
